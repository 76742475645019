<template>
  <div class="container" v-append="message">
   
  </div>
</template>

<script>
// import 'lib-flexible'

import { resetRem } from "@/assets/rem.js";
export default {
  data() {
    return {
        message:""
    };
  },
  created() {
    resetRem();
  },
  mounted() {this.getGuide()
  console.log(document.getElementByClass("video-js"))
  },
  methods: {
      getGuide(){
          var that=this
          this.axios
        .get("/admin/activity/description/"+this.$route.query.activity_id)
        .then((res) => {
          console.log(res)

          that.message=res.data.data.replace('preload="none"','preload="meta"');
        });

      }
  },
};
</script>
<style scoped>
.container{
    background: #f6f6f6;
    height: auto;
    min-height: 100%!important;
    padding: 20px 15px 30px;
}
/deep/ img,/deep/ video{
    max-width: 100%!important;
    height:auto
}
</style>